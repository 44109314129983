import { LOAD_PARTICIPATION_DATA } from "../actions/types";

const initialState = {
  participationData: null,
  regionBounds: null,
  comunasBounds: null,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_PARTICIPATION_DATA:
      return {
        ...state,
        participationData: [],
        regionBounds: payload[0],
        comunasBounds: payload[1],
      };

    default:
      return state;
  }
};

export default reducer;
