import React from "react";
import "./EtapaLegend.css";

const EtapaLegend = ({ ...props }, ref) => {
  return (
    <aside {...props} ref={ref} className="etapa-legend">
      <h3>Plan paso a paso</h3>
      <div className="etapa">
        <div className="etapa-1"></div>
        <p>Cuarentena</p>
      </div>
      <div className="etapa">
        <div className="etapa-2"></div>
        <p>Transición</p>
      </div>
      <div className="etapa">
        <div className="etapa-3"></div>
        <p>Preparación</p>
      </div>
      <div className="etapa">
        <div className="etapa-4"></div>
        <p>Apertura Inicial</p>
      </div>
      <div className="etapa">
        <div className="etapa-5"></div>
        <p>Apertura Avanzada</p>
      </div>
    </aside>
  );
};

export default React.forwardRef(EtapaLegend);
