import { useState, useEffect } from "react";
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
  ReferenceArea,
  Text,
} from "recharts";
import * as d3 from "d3";
import { connect } from "react-redux";

import Selector from "../Selector/Selector";
import { numberWithCommas, toCamelCase } from "../../helpers/StringFormatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import "./GenderElectionParticipation.css";
import axios from "axios";
import Loader from "../Loader/Loader";
const BAR_SIZE = 10;

const edadMapping = {
  "18 a 19": "[0 - 30)",
  "20-24": "[0 - 30)",
  "25-29": "[0 - 30)",
  "30-34": "[30 - 50)",
  "35-39": "[30 - 50)",
  "40-44": "[30 - 50)",
  "45-49": "[30 - 50)",
  "50-54": "[50 - 60)",
  "55-59": "[50 - 60)",
  "60-64": "[60 +",
  "65-69": "[60 +",
  "70-74": "[60 +",
  "75-79": "[60 +",
  "80 0 +": "[60 +",
};
const varsConfig = {
  sexo: {
    bars: [
      { dataKey: "femenino", fill: "#e29578" },
      { dataKey: "masculino", fill: "#006d77" },
    ],
  },
  edad: {
    bars: [
      { dataKey: "[0 - 30)", fill: "#99e2b4" },
      {
        dataKey: "[30 - 50)",
        fill: "#67b99a",
      },
      {
        dataKey: "[50 - 60)",
        fill: "#358f80",
      },
      {
        dataKey: "[60 +",
        fill: "#036666",
      },
    ],
  },
  eleccion: {
    bars: [
      { dataKey: "Presidencial 2017 (1a Vuelta)", fill: "#99e2b4" },
      {
        dataKey: "Presidencial 2017 (2da Vuelta)",
        fill: "#67b99a",
      },
      {
        dataKey: "Plebiscito 2020",
        fill: "#358f80",
      },
      {
        dataKey: "Constituyentes 2021",
        fill: "#036666",
      },
    ],
  },
  Paso: {
    bars: [
      { dataKey: 1, fill: "rgb(235,	93,	97)" },
      { dataKey: 2, fill: "rgb(251,187,46)" },
      { dataKey: 3, fill: "rgb(251,235,63)" },
      { dataKey: 4, fill: "rgb(58,138,202)" },
      { dataKey: 5, fill: "rgb(166,209,242)" },
    ],
  },
};

const variableMap = {
  participacion: "participacion",
  total: "electores",
  votantes: "votantes",
};

const categoryVariables = [
  {
    label: "Elecciones",
    value: "eleccion",
  },
  {
    label: "Plan Paso a Paso",
    value: "Paso",
  },
  {
    label: "Edad",
    value: "edad",
  },
  {
    label: "Sexo",
    value: "sexo",
  },
];

const GenderElectionParticipation = ({ selectedFeature, selectedVariable }) => {
  const [swaped, setSwaped] = useState(false);
  const [data, setData] = useState([]);
  const [hovered, setHovered] = useState(null);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState({
    xAxis: "eleccion",
    category: "sexo",
  });

  const [visibility, setVisibility] = useState({});

  const election = selectedFeature.properties["eleccion"];
  const reduceVar = variableMap[selectedVariable];
  //const [hiddenBars, setHiddenBars] = useState({});

  useEffect(
    () =>
      setVisibility(
        varsConfig[categories.category].bars.reduce(
          (acc, val) => ({ ...acc, [val.dataKey]: true }),
          {}
        )
      ),
    [categories]
  );

  const CustomizedAxisTick = ({ x, y, payload }) => {
    return (
      <Text
        x={x}
        y={y}
        width={75}
        textAnchor="middle"
        verticalAnchor="start"
        style={{
          fontSize: "0.75rem",
          fill:
            payload.value === election
              ? "rgb(49, 144, 55)"
              : "rgb(102, 102, 102)",
          fontWeight: payload.value === election ? 700 : 500,
        }}
      >
        {payload.value}
      </Text>
    );
  };

  useEffect(() => {
    const { layer } = selectedFeature;
    const group = [categories.xAxis, categories.category];

    const idFunc = group.map((g) => (d) => d[g]);

    setLoading(true);
    axios
      .get("https://nodejs.isci.cl/visor-elecciones/records", {
        params: {
          filters: {
            ...(layer.source !== "country" && {
              NOM_REGION: selectedFeature.properties["NOM_REGION"],
            }),
            ...(layer.id === "comunas-layer" && {
              NOM_COMUNA: selectedFeature.properties["NOM_COMUNA"],
            }),
            ...(categories.xAxis !== "eleccion" &&
              categories.category !== "eleccion" && {
                eleccion: selectedFeature.properties["eleccion"],
              }),
          },
          group: group,
        },
      })
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        const rollup = d3
          .rollups(
            response.data
              .filter((d) =>
                d.date &&
                (categories.xAxis === "Paso" || categories.category === "Paso")
                  ? new Date(d.date) >= new Date("01-01-2020")
                  : true
              )
              .sort((a, b) => {
                return a.date - b.date || a.Paso - b.Paso;
              })

              .map((d) => ({
                ...d,
                ...(d.edad ? { edad: edadMapping[d.edad] } : {}),
              }))
              .sort((a, b) =>
                a.edad && b.edad
                  ? Number(a.edad.slice(1, 3)) - Number(b.edad.slice(1, 3))
                  : 0
              ),
            (v) => ({
              electores: d3.sum(v, (d) => d.electores),
              votantes: d3.sum(v, (d) => d.votantes),
              participacion:
                d3.sum(v, (d) => d.votantes) / d3.sum(v, (d) => d.electores),
            }),

            ...idFunc
          )
          .map((d) => ({
            [categories.xAxis]: d[0],
            ...d[1].reduce(
              (acc, v) => ({
                ...acc,
                [v[0]]: v[1][variableMap[selectedVariable]],
                [`${v[0]}_electores`]: v[1]["electores"],
              }),
              {}
            ),
          }));

        setData(
          rollup
            .sort((a, b) =>
              a.edad && b.edad
                ? Number(a.edad.slice(1, 3)) - Number(b.edad.slice(1, 3))
                : 0
            )
            .sort((a, b) =>
              a.Paso && b.Paso ? Number(a.Paso) - Number(b.Paso) : 0
            )
            .map((d) => ({
              ...d,
              ...(d.Paso ? { Paso: `Fase ${d.Paso}` } : {}),
            }))
        );
      });
  }, [selectedFeature, reduceVar, selectedVariable, swaped, categories]);

  const ageTooltipFormater = (value, name, props) => {
    const electores = props.payload[`${props["dataKey"]}_electores`];
    return selectedVariable !== "total"
      ? [
          `${
            selectedVariable === "participacion"
              ? (Math.abs(value) * 100).toFixed(1) + "%"
              : d3.format(".2s")(Math.abs(value))
          } de ${numberWithCommas(electores)} electores`,
          name,
        ]
      : [`${d3.format(".2s")(Math.abs(value))} electores`, name];
  };

  const sexTooltipFormater = (value, name, props) => {
    const electores = props.payload[`${props["dataKey"]}_electores`];
    return selectedVariable !== "total"
      ? [
          `${
            selectedVariable === "participacion"
              ? (Math.abs(value) * 100).toFixed(1) + "%"
              : d3.format(".2s")(Math.abs(value))
          } de ${numberWithCommas(electores)} electores`,
          name,
        ]
      : [`${d3.format(".2s")(Math.abs(value))} electores`, name];
  };

  return (
    <div className="chart-container rounded" style={{ width: "100%" }}>
      <h3>
        {toCamelCase(
          selectedVariable === "total"
            ? "electores"
            : selectedVariable.replace("participacion", "participación")
        )}{" "}
        por {categoryVariables.find((d) => d.value === categories.xAxis).label}{" "}
        y {categoryVariables.find((d) => d.value === categories.category).label}
      </h3>
      <div className="chart-controls">
        <div className="chart-selector">
          <label>Eje X</label>
          <Selector
            value={categories.xAxis}
            onChange={({ target }) =>
              setCategories({ ...categories, xAxis: target.value })
            }
          >
            {categoryVariables.map((v) => {
              if (
                selectedFeature.layer.source === "comunas" &&
                v.value === "Paso"
              )
                return null;
              if (
                (election === "Presidenciales Primera Vuelta" ||
                  election === "Presidenciales Segunda Vuelta") &&
                v.value === "Paso"
              )
                return null;
              return categories.category !== v.value ? (
                <option key={v.value} value={v.value}>
                  {v.label}
                </option>
              ) : null;
            })}
          </Selector>
        </div>
        <div className="chart-selector">
          <label>Categorías</label>
          <Selector
            value={categories.category}
            onChange={({ target }) =>
              setCategories({ ...categories, category: target.value })
            }
          >
            {categoryVariables.map((v) => {
              if (
                selectedFeature.layer.source === "comunas" &&
                v.value === "Paso"
              )
                return null;
              if (
                (election === "Presidenciales Primera Vuelta" ||
                  election === "Presidenciales Segunda Vuelta") &&
                v.value === "Paso"
              )
                return null;
              return categories.xAxis !== v.value ? (
                <option key={v.value} value={v.value}>
                  {v.label}
                </option>
              ) : null;
            })}
          </Selector>
        </div>
        <button
          className="swap-button rounded shadow"
          style={{
            backgroundColor: swaped ? "#319037" : "whitesmoke",
            color: swaped ? "white" : "#474747",
          }}
          onClick={() => {
            setCategories({
              ...categories,
              xAxis: categories.category,
              category: categories.xAxis,
            });
            setSwaped(!swaped);
          }}
        >
          <FontAwesomeIcon icon={faExchangeAlt} />
        </button>
      </div>
      <div className="categorical-chart-container">
        {loading ? <Loader /> : null}

        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={430}
            height={250}
            data={
              categories.xAxis === "edad" ? data.filter((d) => d.edad) : data
            }
            barSize={20}
            barGap={1}
            margin={{
              top: 10,
              right: 10,
              bottom: 0,
              left: 20,
            }}
          >
            <Legend
              layout="horizontal"
              verticalAlign="bottom"
              align="center"
              onClick={(event) => {
                setVisibility({
                  ...visibility,
                  [event.dataKey.trimEnd()]:
                    !visibility[event.dataKey.trimEnd()],
                });
              }}
              onMouseEnter={(event) => {
                setHovered(event.dataKey);
              }}
              onMouseLeave={(event) => {
                setHovered(null);
              }}
            />
            <XAxis
              dataKey={categories.xAxis}
              type="category"
              interval={0}
              height={categories.category === "eleccion" ? 60 : 40}
              tick={CustomizedAxisTick}
            />
            <YAxis
              width={20}
              tickFormatter={(d) =>
                selectedVariable === "participacion"
                  ? `${(d * 100).toFixed(0)}%`
                  : d3.format(".2s")(d)
              }
            />

            <Tooltip
              formatter={
                categories.category === "sexo"
                  ? sexTooltipFormater
                  : ageTooltipFormater
              }
              labelStyle={{ fontSize: "10px" }}
            />
            {categories.xAxis === "eleccion" ? (
              <ReferenceArea
                x1={election}
                x2={election === "Constituyentes 2021" ? null : election}
                strokeOpacity={0.3}
              />
            ) : null}

            {varsConfig[categories.category].bars.map((d) => {
              const pasos = data.map((o) => Object.keys(o)).flat();
              return pasos.includes(`${d.dataKey}`) ||
                categories.category !== "Paso" ? (
                <Bar
                  key={d.dataKey}
                  dataKey={visibility[d.dataKey] ? d.dataKey : d.dataKey + " "}
                  barSize={visibility[d.dataKey] ? BAR_SIZE : 0}
                  radius={[3, 3, 0, 0]}
                  fill={visibility[d.dataKey] ? d.fill : "gray"}
                  opacity={d.dataKey === hovered || !hovered ? 1 : 0.3}
                />
              ) : null;
            })}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  participationData: state.data.participationData,
  selectedFeature: state.mapState.selectedFeature,
});
export default connect(mapStateToProps, {})(GenderElectionParticipation);
