import { SET_SELECTED_FEATURE } from "../actions/types";

const initialState = {
  selectedFeature: null,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SELECTED_FEATURE:
      return { ...state, selectedFeature: payload };
    default:
      return state;
  }
};

export default reducer;
