import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Selector from "../Selector/Selector";
import _ from "lodash";

import {
  faLayerGroup,
  faFilter,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { turnoutD3ColorScale } from "../../helpers/ColorScales";
import { hideLayer, showLayer } from "../../helpers/MapboxInteractions";
import { layerVariables } from "../../config/variables";
import { createCustomColorScale } from "../../helpers/ColorScales";

import { setSelectedFeature } from "../../actions/mapState";
import { mapConfig } from "../../config/map";
import "./LayersContent.css";
import axios from "axios";

const LayersContent = ({
  map,
  mapActive,
  election,
  // etapaLegend,
  filters,
  setFilters,
  //participationData,
  setElection,
  selectedFeature,
  setSelectedFeature,
  selectedLayer,
  setSelectedLayer,
  selectedVariable,
  setSelectedVariable,
  infoVariable,
}) => {
  const [minScale, setMinScale] = useState("0%");
  const [maxScale, setMaxScale] = useState("100%");
  const [elections, setElections] = useState([]);

  useEffect(() => {
    if (!map.current || !mapActive) return;

    // layer selector

    switch (selectedLayer) {
      case "locales-layer":
        //showLayer(map.current, "locales-layer");
        hideLayer(map.current, "comunas-layer");
        hideLayer(map.current, "comunas-outline-layer");
        hideLayer(map.current, "regiones-layer");
        hideLayer(map.current, "regiones-outline-layer");
        break;
      case "comunas-layer":
        showLayer(map.current, "comunas-layer");
        showLayer(map.current, "comunas-outline-layer");
        //hideLayer(map.current, "locales-layer");
        hideLayer(map.current, "regiones-layer");
        hideLayer(map.current, "regiones-outline-layer");
        break;
      case "regiones-layer":
        showLayer(map.current, "regiones-layer");
        //hideLayer(map.current, "locales-layer");
        hideLayer(map.current, "comunas-layer");
        hideLayer(map.current, "comunas-outline-layer");
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    axios
      .get("https://nodejs.isci.cl/visor-elecciones/elections")
      .then((response) => {
        setElections(response.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://nodejs.isci.cl/visor-elecciones/regions")
      .then((response) => {
        setFilters({ ...filters, regions: response.data });
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!map.current) return;
    //let rollups;
    axios
      .get("https://nodejs.isci.cl/visor-elecciones/records", {
        params: {
          filters: {},
          group: [
            ...(selectedLayer === "regiones-layer"
              ? ["NOM_REGION", "eleccion"]
              : []),
            ...(selectedLayer === "comunas-layer"
              ? ["NOM_COMUNA", "eleccion"]
              : []),
          ],
        },
      })
      .then((response) => {
        const rollups = response.data;
        const scaleType =
          selectedVariable === "participacion" ? "percentage" : "number";
        const [scale, dataMin, dataMax] = createCustomColorScale(
          rollups,
          selectedVariable,
          scaleType
        );

        const varTickFormatter = layerVariables[
          selectedLayer.replace("-layer", "")
        ].find((v) => v.key === selectedVariable || v.defaultX);
        if (varTickFormatter.tickFormatter) {
          setMinScale(varTickFormatter.tickFormatter(dataMin));
          setMaxScale(varTickFormatter.tickFormatter(dataMax));
        } else {
          setMinScale(dataMin.toFixed(2));
          setMaxScale(
            selectedVariable === "participacion"
              ? dataMax * 100
              : dataMax.toFixed(2)
          );
        }
        map.current.setPaintProperty(
          selectedLayer,
          selectedLayer === "locales-layer" ? "circle-color" : "fill-color",
          scale
        );
      });
    /*
    if (selectedLayer === "regiones-layer") {
      rollups = d3
        .rollups(
          participationData,
          (v) => ({
            total: d3.sum(v, (d) => d.total),
            votantes: d3.sum(v, (d) => d.votantes),
            participacion:
              d3.sum(v, (d) => d.votantes) / d3.sum(v, (d) => d.total),
          }),
          (d) => d["NOM_REGION"],
          (d) => d.eleccion
        )
        .map((d) => d[1].map((d) => d[1]))
        .flat();
    } else {
      rollups = d3
        .rollups(
          participationData,
          (v) => ({
            total: d3.sum(v, (d) => d.total),
            votantes: d3.sum(v, (d) => d.votantes),
            participacion:
              d3.sum(v, (d) => d.votantes) / d3.sum(v, (d) => d.total),
          }),
          (d) => d["NOM_COMUNA"],
          (d) => d.eleccion
        )
        .map((d) => d[1].map((d) => d[1]))
        .flat();
    } */

    // eslint-disable-next-line
  }, [selectedLayer, selectedVariable]);

  const handleChangeElection = ({ target }) => {
    let filter;

    const features = map.current.querySourceFeatures(
      selectedLayer.replace("-layer", ""),
      {
        sourceLayer: selectedLayer,
      }
    );

    if (selectedLayer === "regiones-layer" && selectedFeature) {
      filter = (d) =>
        d.properties["NOM_REGION"] ===
          selectedFeature.properties["NOM_REGION"] &&
        d.properties["eleccion"] === target.value;
    } else if (selectedLayer === "comunas-layer" && selectedFeature) {
      filter = (d) =>
        d.properties["NOM_REGION"] ===
          selectedFeature.properties["NOM_REGION"] &&
        d.properties["NOM_COMUNA"] ===
          selectedFeature.properties["NOM_COMUNA"] &&
        d.properties["eleccion"] === target.value;
    } else if (selectedLayer === "locales-layer" && selectedFeature) {
      filter = (d) =>
        d.properties["NOM_REGION"] ===
          selectedFeature.properties["NOM_REGION"] &&
        d.properties["NOM_COMUNA"] ===
          selectedFeature.properties["NOM_COMUNA"] &&
        d.properties["Local"] === selectedFeature.properties["Local"] &&
        d.properties["eleccion"] === target.value;
    }

    if (selectedFeature && selectedFeature.layer.source === "country") {
      setSelectedFeature({
        layer: { source: "country" },
        properties: { eleccion: target.value },
      });
      setElection(target.value);
    } else {
      const newFeature = selectedFeature ? features.find(filter) : null;

      if (newFeature) {
        newFeature.layer = {
          source: selectedLayer.replace("-layer", ""),
          id: selectedLayer,
        };

        setSelectedFeature(newFeature);
      }

      setElection(target.value);
    }
  };

  useEffect(() => {
    const mapboxFilters = ["all"];
    if (filters.selectedRegion !== "all") {
      const mapboxRegionFilter = [
        "==",
        ["get", "NOM_REGION"],
        filters.selectedRegion,
      ];
      mapboxFilters.push(mapboxRegionFilter);
    }

    if (filters.selectedCommune !== "all") {
      const mapboxCommuneFilter = [
        "==",
        ["get", "NOM_COMUNA"],
        filters.selectedCommune,
      ];
      mapboxFilters.push(mapboxCommuneFilter);
    }

    if (filters.selectedLocal !== "all") {
      const mapboxLocalFilter = ["==", ["get", "Local"], filters.selectedLocal];
      mapboxFilters.push(mapboxLocalFilter);
    }
  });

  /*
  useEffect(() => {
    const data = participationData.filter(
      (d) => d.eleccion === election && d["NOM_REGION"]
    );

    const regionsList = _.uniq(data.map((d) => d["NOM_REGION"])); // get unique regions

    setFilters({ ...filters, regions: regionsList });
    // eslint-disable-next-line
  }, []);
  */

  const handleRegionChange = ({ target }) => {
    const selectedRegion = target.value;
    if (selectedRegion !== "all") {
      axios
        .get("https://nodejs.isci.cl/visor-elecciones/communes", {
          params: {
            NOM_REGION: selectedRegion,
            eleccion: election,
          },
        })
        .then((response) => {
          setFilters({
            ...filters,
            communes: response.data,
            selectedRegion,
            selectedCommune: "all",
            selectedLocal: "all",
          });
        });
    } else {
      setFilters({
        ...filters,
        communes: [],
        locales: [],
        selectedRegion: "all",
        selectedCommune: "all",
        selectedLocal: "all",
      });
    }
  };

  const handleCommuneChange = ({ target }) => {
    const selectedCommune = target.value;
    if (selectedCommune) {
      setFilters({
        ...filters,
        selectedCommune,
        selectedLocal: "all",
      });
    } else {
      setFilters({
        ...filters,
        selectedCommune: "all",
        selectedLocal: "all",
        communes: [],
      });
    }
  };

  const handleLocalChange = ({ target }) => {
    const selectedLocal = target.value;
    setFilters({
      ...filters,
      selectedLocal: selectedLocal,
    });
  };

  return (
    <>
      <div className="section-header">
        <h3>Capas</h3>
        <FontAwesomeIcon icon={faLayerGroup} />
      </div>
      <label htmlFor="election">Elección</label>

      <Selector
        id="election"
        defaultValue={election}
        onChange={handleChangeElection}
      >
        {elections.map((e, i) => (
          <option key={i} value={`${e}`}>
            {e}
          </option>
        ))}
      </Selector>

      <label htmlFor="layer">División Geográfica</label>

      <Selector
        id="layer"
        defaultValue={selectedLayer}
        onChange={({ target }) => setSelectedLayer(target.value)}
      >
        <option value="regiones-layer">Regiones</option>
        <option value="comunas-layer">Comunas</option>
        {/*
        <option value="locales-layer">Locales de Votación</option>
        */}
      </Selector>
      <button
        className="country-results"
        onClick={() => {
          if (selectedFeature && selectedFeature.layer.source === "country") {
            return null;
          } else {
            map.current.once("moveend", () => {
              setFilters({
                ...filters,
                selectedCommune: "all",
                selectedRegion: "all",
                communes: [],
              });
            });
            map.current.flyTo({
              center: [mapConfig.lng, mapConfig.lat],
              zoom: mapConfig.zoom,
            });
            setSelectedFeature({
              layer: { source: "country" },
              properties: { eleccion: election },
            });
          }
        }}
      >
        Ver Resultados País
      </button>
      {/* 
      <div className="layers-group">
        {selectedLayer === "locales-layer" ? (
          <div className="layer-toggle">
            <label htmlFor="etapa-checkbox">
              Mostrar Plan Paso a Paso <br />
              (Comunas)
            </label>
            <input
              id="etapa-checkbox"
              type="checkbox"
              value={etapaProperties.visible}
              onChange={({ target }) =>
                setEtapaProperties({
                  ...etapaProperties,
                  visible: target.checked,
                })
              }
            />
           
            <div className="break" />
             
            {etapaProperties.visible ? (
              <>
                {" "}
                <Message delay={3000}>
                  Ahora la capa de comunas muestra las etapas del plan paso a
                  paso
                </Message>
                <div className="slider">
                  <label htmlFor="etapa-opacity">Transparencia</label>
                  <input
                    className="range-input"
                    id="etapa-opacity"
                    type="range"
                    value={1 - etapaProperties.opacity}
                    onChange={({ target }) =>
                      setEtapaProperties({
                        ...etapaProperties,
                        opacity: 1 - Number(target.value),
                      })
                    }
                    min={0}
                    max={1}
                    step={0.1}
                  />
                </div>
              </>
            ) : null}
          </div>
        ) : null} 
      </div>
      */}

      <div className="filters">
        <div className="section-header">
          <h3>Filtros</h3>
          <FontAwesomeIcon icon={faFilter} />
        </div>
        <ul>
          <li className="filter">
            <label htmlFor="region-selector">Región</label>
            <Selector
              id="region-selector"
              onChange={handleRegionChange}
              value={filters.selectedRegion}
            >
              <option value={"all"}>Todas</option>
              {filters.regions.map((r) => (
                <option value={r} key={r}>
                  {r}
                </option>
              ))}
            </Selector>
          </li>

          {filters.selectedRegion !== "all" ? (
            selectedLayer !== "regiones-layer" ? (
              <li className="filter">
                <label htmlFor="commune-selector">Comuna</label>
                <Selector
                  id="commune-selector"
                  value={filters.selectedCommune}
                  onChange={handleCommuneChange}
                >
                  <option value="all">Todas</option>
                  {filters.communes.map((r) => (
                    <option value={r} key={r}>
                      {r}
                    </option>
                  ))}
                </Selector>
              </li>
            ) : (
              <p className="filter-info">
                Selecciona "Comunas" como división geográfica para filtrar por
                comunas.
              </p>
            )
          ) : null}

          {filters.selectedCommune !== "all" &&
          selectedLayer !== "regiones-layer" &&
          selectedLayer !== "comunas-layer" ? (
            <li className="filter">
              <label htmlFor="local-selector">Local</label>
              <Selector
                id="local-selector"
                value={filters.selectedLocal}
                onChange={handleLocalChange}
              >
                <option value="all">Todos</option>
                {filters.locales.map((r) => (
                  <option value={r} key={r}>
                    {r}
                  </option>
                ))}
              </Selector>
            </li>
          ) : null}
        </ul>
      </div>
      <div className="color-legend">
        <label className="variable-info" htmlFor="variable">
          Variable{" "}
          <FontAwesomeIcon
            className="info-icon"
            style={{ color: "#35963c" }}
            onClick={() => {
              infoVariable.current.style.display = "block";
            }}
            icon={faInfoCircle}
          />
        </label>
        <Selector
          id="variable"
          defaultValue={selectedVariable}
          onChange={({ target }) => setSelectedVariable(target.value)}
        >
          {layerVariables[selectedLayer.replace("-layer", "")]
            .filter((v) => v.key !== "Etapa_plan")
            .map((v) => (
              <option key={v.key} value={v.key}>
                {v.name}
              </option>
            ))}
        </Selector>
        <div
          className="row colors rounded"
          style={{
            background: `linear-gradient(to right, ${_.range(0, 1.1, 0.1)
              .map((br) => turnoutD3ColorScale(br))
              .join(",")})`,
          }}
        ></div>
        <div className="row legend-labels">
          <div className="label">{minScale}</div>
          <div className="label">{maxScale}</div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedFeature: state.mapState.selectedFeature,
  participationData: state.data.participationData,
});
export default connect(mapStateToProps, { setSelectedFeature })(LayersContent);
