import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import Loader from "../Loader/Loader";
import "./LandingPage.css";
import ISCILogo from "../../images/Logo-ISCI.png";
import INDLogo from "../../images/Logo-IND.jpeg";

const LandingPage = React.forwardRef(({ loading, setMapActive }, ref) => {
  function goToMap() {
    ref.current.classList.add("hidden");
    setMapActive(true);
  }

  return (
    <div ref={ref} className="home flex-container column">
      <h1>Visor Elecciones</h1>
      <h2>Visor territorial de las últimas elecciones en Chile.</h2>
      <p className="first">
        Esta aplicación tiene como objetivo comprender geográfica y
        demográficamente las características de la participación electoral en
        las últimas elecciones del país. Para aquello utilizamos los datos
        disponibilizados públicamente por el Servicio Electoral (SERVEL) y los
        desplegamos en un mapa interactivo y un dashboard que muestran los
        resultados de participación electoral, por edad y sexo, a partir de las
        elecciones presidenciales del 2017 y a nivel de comuna y región.
      </p>
      <p className="second">
        Además, continuando los esfuerzos del ISCI por aportar a comprender los
        impactos de la pandemia de COVID-19 en nuestro país, incluimos los
        efectos que el Plan Paso a Paso, impulsado por el Ministerio de Salud,
        ha tenido en la participación de las personas en las últimas elecciones.
      </p>

      <p className="team">
        Equipo: Sebastián Cancino, Susana Mondschein, Marcelo Olivares, Sofía
        Pontigo
      </p>

      <button
        className="btn-primary rounded"
        id="go-to-map"
        onClick={goToMap}
        disabled={loading}
      >
        {loading ? (
          <>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin btn-icon" />
            <span>Cargando Mapa</span>
          </>
        ) : (
          "Ir al mapa"
        )}
      </button>
      <div className="logos">
        <img className="logo-isci" src={ISCILogo} alt="Logo ISCI" />
        <img className="logo-ind" src={INDLogo} alt="Logo ISCI" />
      </div>
      {loading ? (
        <div id="map-loader">
          <Loader />
        </div>
      ) : null}
    </div>
  );
});

export default LandingPage;
