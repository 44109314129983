import axios from "axios";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { numberWithCommas } from "../../helpers/StringFormatting";

import VoteIcon from "../../images/icons/vote.svg";
import VotersIcon from "../../images/icons/voters.svg";

import GenderParticipationBar from "../GenderAgeParticipation/GenderAgeParticipation";
import GenderElectionParticipation from "../GenderElectionParticipation/GenderElectionParticipation";
import RegionsParticipationBar from "../RegionsParticipationBar/RegionsParticipationBar";

const CountryFeatures = ({ selectedFeature, selectedVariable }) => {
  const election = selectedFeature.properties["eleccion"];
  const [properties, setProperties] = useState({});
  useEffect(() => {
    axios
      .get("https://nodejs.isci.cl/visor-elecciones/records", {
        params: {
          filters: {},
          group: ["eleccion"],
        },
      })
      .then((response) => {
        const electionData = response.data.find(
          (d) => d["eleccion"] === election
        );
        setProperties({
          total: electionData.electores,
          votantes: electionData.votantes,
          participacion: electionData.participacion,
        });
      });
  }, [election]);

  return (
    <>
      <header>
        <div>
          <h2>Chile</h2>
          <p>Elección {election}</p>
        </div>
      </header>
      <section className="content">
        <div className="features">
          <div className="feature-group">
            <div className="feature">
              <img src={VotersIcon} alt="Icono Votantes" />
              <p className="feature-value">
                {" "}
                {numberWithCommas(properties["total"])}
              </p>
              <p className="feature-label">Electores</p>
            </div>
            <div className="feature">
              <img src={VoteIcon} alt="Icono Urna de Votación" />
              <p className="feature-value">
                {(properties["participacion"] * 100).toFixed(1)}%
              </p>
              <p className="feature-label">Participación</p>
            </div>
          </div>
        </div>
        <GenderParticipationBar selectedVariable={selectedVariable} />
        <GenderElectionParticipation selectedVariable={selectedVariable} />
        <RegionsParticipationBar selectedVariable={selectedVariable} />
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  participationData: state.data.participationData,
  selectedFeature: state.mapState.selectedFeature,
});

export default connect(mapStateToProps, {})(CountryFeatures);
