import { useEffect, useRef, useState } from "react";
import LandingPage from "../LandingPage/LandingPage";
import Map from "../Map/Map";

import { connect } from "react-redux";

import "./App.css";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import { loadData } from "../../actions/data";

function App({ loadData, participationData }) {
  const [loading, setLoading] = useState(true);
  const [mapActive, setMapActive] = useState(false);
  const landing = useRef(null);
  const loadingOverlay = useRef(null);
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="App">
      <LoadingOverlay ref={loadingOverlay} />
      <LandingPage
        ref={landing}
        loading={loading}
        setMapActive={setMapActive}
      />
      {participationData ? (
        <Map
          setLoading={setLoading}
          mapActive={mapActive}
          landing={landing}
          loadingOverlay={loadingOverlay}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  participationData: state.data.participationData,
});

export default connect(mapStateToProps, {
  loadData,
})(App);
