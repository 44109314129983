import { useEffect, useRef } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { mapConfig } from "../config/map";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const useMap = (setLoading) => {
  const map = useRef(null);
  const mapContainer = useRef(null);

  useEffect(() => {
    // define map
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: process.env.REACT_APP_MAPBOX_STYLE_URL,
      center: [mapConfig.lng, mapConfig.lat],
      zoom: mapConfig.zoom,
    });
  });

  return [map, mapContainer];
};

export default useMap;
