import { toCamelCase, numberWithCommas } from "../../helpers/StringFormatting";
import VoteIcon from "../../images/icons/vote.svg";
import VotersIcon from "../../images/icons/voters.svg";
// import CovidIcon from "../../images/icons/virus.png";
import "./RegionFeatures.css";
// import Comunas from "../Comunas/Comunas";
// import { etapas } from "../../config/etapas";
import GenderParticipationBar from "../GenderAgeParticipation/GenderAgeParticipation";
import GenderElectionParticipation from "../GenderElectionParticipation/GenderElectionParticipation";
import { useEffect } from "react";
import axios from "axios";

const RegionFeatures = ({ selectedFeature, selectedVariable }) => {
  const { properties } = selectedFeature ? selectedFeature : {};
  useEffect(() => {
    axios
      .get("https://nodejs.isci.cl/visor-elecciones/records", {
        params: {
          filters: {
            NOM_REGION: properties["NOM_REGION"],

            eleccion: properties["eleccion"],
          },
          group: ["Paso", "sexo"],
        },
      })
      .then((response) => {
        console.log(response.data);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <header>
        <div>
          <h2>{toCamelCase(properties["NOM_REGION"])}</h2>
          <p>Elección {properties["eleccion"]}</p>
        </div>
      </header>
      <section className="content">
        <div className="features">
          <div className="feature-group">
            <div className="feature">
              <img src={VotersIcon} alt="Icono Votantes" />
              <p className="feature-value">
                {" "}
                {numberWithCommas(properties["total"])}
              </p>
              <p className="feature-label">Electores</p>
            </div>
            <div className="feature">
              <img src={VoteIcon} alt="Icono Urna de Votación" />
              <p className="feature-value">
                {((properties["votantes"] / properties["total"]) * 100).toFixed(
                  1
                )}
                %
              </p>
              <p className="feature-label">Participación</p>
            </div>
            {/*
            <div className="feature">
              <img src={CovidIcon} alt="Icono Urna de Votación" />
              <p className="feature-value">
                {numberWithCommas(properties["CasosNuevos100_2"].toFixed(1))}
              </p>
              <p className="feature-label">Contagios</p>
            </div>
            */}
          </div>
          {/*
          <div className="etapas">
            {etapas.map((e) => {
              if (properties["Etapa" + e.id] > 0) {
                return (
                  <p
                    key={e.id}
                    className="etapa-comunas rounded"
                    style={{
                      backgroundColor: e.color,
                    }}
                  >
                    <span className="etapa-value">
                      {properties["Etapa" + e.id]}
                    </span>{" "}
                    <br />
                    comunas en <br />
                    <span className="etapa-name">{e.name}</span>
                  </p>
                );
              }
              return null;
            })}
          </div>
          */}
        </div>

        <GenderParticipationBar selectedVariable={selectedVariable} />
        <GenderElectionParticipation
          selectedFeature={selectedFeature}
          selectedVariable={selectedVariable}
        />
      </section>
    </>
  );
};

export default RegionFeatures;
