import { toCamelCase, numberWithCommas } from "../../helpers/StringFormatting";
import VoteIcon from "../../images/icons/vote.svg";
import VotersIcon from "../../images/icons/voters.svg";
// import EtapaBadge from "../EtapaBadge/EtapaBadge";

const LocalFeatures = ({ selectedFeature }) => {
  const { properties } = selectedFeature ? selectedFeature : {};
  return (
    <>
      <header>
        <div>
          <h2>
            {properties["Local"]}, Circunscripcion{" "}
            {toCamelCase(properties["Circunscripcion"])}{" "}
          </h2>
          <h3>
            Comuna De {toCamelCase(properties["NOM_COMUNA"])}, Región{" "}
            {toCamelCase(properties["NOM_REGION"])}
          </h3>

          <p>Elección {properties["Eleccion"]}</p>
        </div>
      </header>
      <section className="content">
        <div className="features">
          <div className="feature-group">
            <div className="feature">
              <img src={VotersIcon} alt="Icono Votantes" />
              <p className="feature-value">
                {" "}
                {numberWithCommas(properties["Electores"])}
              </p>
              <p className="feature-label">Electores</p>
            </div>
            <div className="feature">
              <img src={VoteIcon} alt="Icono Urna de Votación" />
              <p className="feature-value">
                {(properties["Turnout"] * 100).toFixed(1)}%
              </p>
              <p className="feature-label">Participación</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LocalFeatures;
