import { toCamelCase, numberWithCommas } from "../../helpers/StringFormatting";
import VoteIcon from "../../images/icons/vote.svg";
import VotersIcon from "../../images/icons/voters.svg";

import GenderParticipationBar from "../GenderAgeParticipation/GenderAgeParticipation";
import GenderElectionParticipation from "../GenderElectionParticipation/GenderElectionParticipation";

const ComunaFeatures = ({ selectedFeature, selectedVariable }) => {
  const { properties } = selectedFeature ? selectedFeature : {};

  return (
    <>
      <header>
        <div>
          <h2>
            {toCamelCase(properties["NOM_COMUNA"])},{" "}
            {toCamelCase(properties["NOM_REGION"])}
          </h2>
          <p>Elección {properties["eleccion"]}</p>
        </div>
      </header>
      <section className="content">
        <div className="features">
          <div className="feature-group">
            <div className="feature">
              <img src={VotersIcon} alt="Icono Votantes" />
              <p className="feature-value">
                {numberWithCommas(properties["total"])}
              </p>
              <p className="feature-label">Electores</p>
            </div>
            <div className="feature">
              <img src={VoteIcon} alt="Icono Urna de Votación" />
              <p className="feature-value">
                {((properties["votantes"] / properties["total"]) * 100).toFixed(
                  1
                )}
                %
              </p>
              <p className="feature-label">Participación</p>
            </div>
          </div>
        </div>
        <GenderParticipationBar selectedVariable={selectedVariable} />
        <GenderElectionParticipation
          selectedFeature={selectedFeature}
          selectedVariable={selectedVariable}
        />
      </section>
    </>
  );
};

export default ComunaFeatures;
