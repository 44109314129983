import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./FeaturesDisplay.css";
import { useRef } from "react";
import LocalFeatures from "../LocalFeatures/LocalFeatures";
import RegionFeatures from "../RegionFeatures/RegionFeatures";
import ComunaFeatures from "../ComunaFeatures/ComunaFeatures";
import CountryFeatures from "../CountryFeatures/CountryFeatures";
import { connect } from "react-redux";

const FeaturesDisplay = ({ selectedFeature, selectedVariable }) => {
  const display = useRef(null);

  const source = selectedFeature ? selectedFeature.layer.source : null;

  let featureContent;

  switch (source) {
    case "country":
      featureContent = (
        <CountryFeatures
          election={selectedFeature.election}
          selectedVariable={selectedVariable}
        />
      );
      if (display.current) {
        display.current.classList.remove("hidden");
      }
      break;
    case "locales":
      featureContent = <LocalFeatures selectedFeature={selectedFeature} />;

      if (display.current) {
        display.current.classList.remove("hidden");
      }

      break;
    case "regiones":
      featureContent = (
        <RegionFeatures
          selectedFeature={selectedFeature}
          selectedVariable={selectedVariable}
        />
      );

      if (display.current) {
        display.current.classList.remove("hidden");
      }

      break;
    case "comunas":
      featureContent = (
        <ComunaFeatures
          selectedFeature={selectedFeature}
          selectedVariable={selectedVariable}
        />
      );

      if (display.current) {
        display.current.classList.remove("hidden");
      }

      break;
    default:
      featureContent = null;

      if (display.current) {
        display.current.classList.add("hidden");
      }

      break;
  }

  return (
    <aside ref={display} className="feature-display hidden shadow">
      <div className="close-container">
        <span
          className="close"
          onClick={() => {
            display.current.classList.add("hidden");
          }}
        >
          <FontAwesomeIcon icon={faTimes} className="zoom btn-icon" />
        </span>
      </div>
      {featureContent}
    </aside>
  );
};

const mapStateToProps = (state) => ({
  selectedFeature: state.mapState.selectedFeature,
});

export default connect(mapStateToProps, {})(FeaturesDisplay);
