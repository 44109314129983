import React from "react";
import Loader from "../Loader/Loader";
import "./LoadingOverlay.css";
const LoadingOverlay = React.forwardRef((props, ref) => {
  return (
    <div {...props} ref={ref} className="loading-overlay">
      <p>Cargando Datos</p>
      <Loader />
    </div>
  );
});

export default LoadingOverlay;
