import { useState, useEffect } from "react";
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
  Cell,
  LabelList,
  Text,
} from "recharts";
import axios from "axios";
import { toCamelCase } from "../../helpers/StringFormatting";

import * as d3 from "d3";
import { connect } from "react-redux";

import { numberWithCommas } from "../../helpers/StringFormatting";
import { createCustomColorScale } from "../../helpers/ColorScales";
import "./RegionsParticipationBar.css";

const BAR_SIZE = 20;

const CustomizedAxisTick = ({ x, y, payload }) => (
  <Text
    x={x}
    y={y}
    width={250}
    textAnchor="middle"
    verticalAnchor="start"
    style={{ fontSize: "0.875rem" }}
  >
    {""}
  </Text>
);

const variableMap = {
  participacion: "participacion",
  total: "electores",
  votantes: "votantes",
};

const RegionsParticipationBar = ({ selectedFeature, selectedVariable }) => {
  const [data, setData] = useState([]);
  const reduceVar = variableMap[selectedVariable];

  useEffect(() => {
    const { eleccion } = selectedFeature.properties;
    axios
      .get("https://nodejs.isci.cl/visor-elecciones/records", {
        params: {
          filters: {
            eleccion,
          },
          group: ["NOM_REGION"],
        },
      })
      .then((response) => {
        const regions = response.data
          .map((d) => ({
            [reduceVar]: d[reduceVar],
            region: d.NOM_REGION,
            region_electores: d.electores,
          }))
          .sort((a, b) => b[reduceVar] - a[reduceVar]);
        setData(regions);
      });
  }, [selectedFeature.properties, reduceVar]);
  /*
  useEffect(() => {
    const { eleccion } = selectedFeature.properties;
    const rollups = d3
      .rollups(
        participationData.filter((d) => d["eleccion"] === eleccion),
        (v) => ({
          participacion:
            d3.sum(v, (d) => d.votantes) / d3.sum(v, (d) => d.total),
          votantes: d3.sum(v, (d) => d.votantes),
          total: d3.sum(v, (d) => d.total),
        }),
        (d) => d["NOM_REGION"]
      )
      .map((d) => ({
        [reduceVar]: d[1][reduceVar],
        region: d[0],
        region_electores: d[1].total,
      }))
      .sort((a, b) => b[reduceVar] - a[reduceVar]);
    console.log(rollups.filter((d) => d.region));
    setData(rollups.filter((d) => d.region));
  }, [participationData, selectedFeature, reduceVar]);
*/
  const [, minData, maxData] = createCustomColorScale(data, selectedVariable);

  const CustomizedLabel = (props) => {
    const { x, y, value } = props;

    return (
      <text
        x={x}
        y={y}
        className="bar-label"
        dy={14}
        dx={5}
        fontSize={6}
        textAnchor="start"
        style={{
          fill: reduceVar === "participacion" ? "rgb(102, 102, 102)" : "white",
        }}
      >
        {value
          ? toCamelCase(
              value
                .replace("REGIÓN DE ", "")
                .replace("REGIÓN DEL ", "")
                .replace("REGIÓN ", "")
            )
          : value}
      </text>
    );
  };

  const regionTooltipFormater = (value, name, props) => {
    const electores = props.payload[`region_electores`];
    return selectedVariable !== "total"
      ? [
          `${
            selectedVariable === "participacion"
              ? (Math.abs(value) * 100).toFixed(1) + "%"
              : d3.format(".2s")(Math.abs(value))
          } de ${numberWithCommas(electores)} electores`,
          name,
        ]
      : [`${d3.format(".2s")(Math.abs(value))} electores`, name];
  };
  return (
    <div className="chart-container rounded" style={{ height: 500 }}>
      <h3>
        {reduceVar === "total"
          ? "electores"
          : reduceVar.replace("participacion", "participación")}{" "}
        por Región
      </h3>

      <ResponsiveContainer className="loading-chart" width="100%" height="90%">
        <BarChart
          width={430}
          height={250}
          data={data}
          barSize={10}
          layout="vertical"
          barCategoryGap={30}
          margin={{
            top: 10,
            right: 10,
            bottom: 10,
            left: 0,
          }}
        >
          <XAxis
            width={20}
            scale={
              reduceVar === "participacion" ? "auto" : d3.scaleLog().base(10)
            }
            domain={
              reduceVar === "participacion" ? [0, "auto"] : [0.01, "auto"]
            }
            tickFormatter={(d) =>
              reduceVar === "participacion"
                ? (d * 100).toFixed(0) + "%"
                : d3.format(".1s")(d)
            }
            type="number"
            name="participacion"
          />
          <Tooltip formatter={regionTooltipFormater} />

          <Bar
            dataKey={reduceVar}
            barSize={BAR_SIZE}
            radius={[0, 5, 5, 0]}
            fill={"rgb(3,	102,	102	)"}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={d3.interpolateRdYlGn(
                  reduceVar === "participacion"
                    ? entry[reduceVar]
                    : (entry[reduceVar] - minData) / maxData
                )}
              />
            ))}

            <LabelList
              dataKey="region"
              position="insideLeft"
              content={CustomizedLabel}
              style={{ fill: "white", fontSize: "0.875rem" }}
            />
          </Bar>

          <YAxis
            interval={0}
            width={1}
            dataKey="region"
            type="category"
            tick={CustomizedAxisTick}
            name="region"
            tickLine={false}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  participationData: state.data.participationData,
  selectedFeature: state.mapState.selectedFeature,
});
export default connect(mapStateToProps, {})(RegionsParticipationBar);
