import { combineReducers } from "redux";
import layers from "./layers";
import mapState from "./mapState";
import data from "./data";

export default combineReducers({
  layers,
  mapState,
  data,
});
