// layers.js

export const LOAD_MESAS_DATA = "LOAD_MESAS_DATA";
export const LOAD_LOCALES_LAYER = "LOAD_LOCALES_LAYER";
export const LOAD_COMUNAS_LAYER = "LOAD_COMUNAS_LAYER";
export const LOAD_REGIONES_LAYER = "LOAD_REGIONES_LAYER";
export const LOAD_LAYERS = "LOAD_LAYERS";

// mapState.js
export const SET_SELECTED_FEATURE = "SET_SELECTED_FEATURE";

export const LOAD_PARTICIPATION_DATA = "LOAD_PARTICIPATION_DATA";
