import { useEffect, useState, useRef } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { connect } from "react-redux";

import "./Map.css";
import Console from "../Console/Console";
import FeaturesDisplay from "../FeaturesDisplay/FeaturesDisplay";
import LocationTooltip from "../LocationTooltip/LocationTooltip";
import useComunasLayer from "../../hooks/useComunasLayer";
import useMap from "../../hooks/useMap";
// import useLocalesLayer from "../../hooks/useLocalesLayer";
import useRegionesLayer from "../../hooks/useRegionesLayer";
import { setSelectedFeature } from "../../actions/mapState";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import VariableInfo from "../VariableInfo/VariableInfo";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = ({
  setLoading,
  mapActive,
  layers,
  landing,
  loadingOverlay,
  mapState,
  setSelectedFeature,
  regionBounds,
  comunasBounds,
}) => {
  const [map, mapContainer] = useMap(setLoading);
  const { selectedFeature } = mapState;
  const infoVariable = useRef();
  const [election, setElection] = useState("Constituyentes 2021");
  const [location, setLocation] = useState(null);
  const { width, height } = useWindowDimensions();
  const [selectedVariable, setSelectedVariable] = useState("participacion");

  useComunasLayer(
    map,
    setSelectedFeature,
    setLocation,
    comunasBounds,
    width,
    height
  );
  useRegionesLayer(
    map,
    setSelectedFeature,
    setLocation,
    regionBounds,
    width,
    height
  );

  useEffect(() => {
    map.current.on("idle", () => {
      const existingLayers = [map.current.getLayer("regiones-layer")];
      if (existingLayers.every((layer) => !!layer)) setLoading(false);
    });
  });

  useEffect(() => {
    map.current.on("load", () => {
      landing.current.classList.add("animate");
      loadingOverlay.current.classList.add("fade-out");
    });
  });

  useEffect(() => {
    if (mapActive) {
      map.current.addControl(new mapboxgl.NavigationControl(), "bottom-left");
    }
  }, [map, mapActive]);

  return (
    <main>
      <VariableInfo ref={infoVariable} />
      <Console
        {...{
          mapActive,
          election,
          setElection,
          map,
          setSelectedFeature,
          selectedVariable,
          setSelectedVariable,
          infoVariable,
        }}
      />

      <LocationTooltip location={location} />

      <FeaturesDisplay
        selectedFeature={selectedFeature}
        selectedVariable={selectedVariable}
      />

      <div ref={mapContainer} className="map-container" />
    </main>
  );
};

const mapStateToProps = (state) => ({
  layers: state.layers,
  mapState: state.mapState,
  regionBounds: state.data.regionBounds,
  comunasBounds: state.data.comunasBounds,
});
export default connect(mapStateToProps, { setSelectedFeature })(Map);
