import { forwardRef } from "react";
import { MathComponent } from "mathjax-react";
import VoteIcon from "../../images/icons/vote.svg";
import VotersIcon from "../../images/icons/voters.svg";
import ElectorsIcon from "../../images/icons/electors.png";
import "./VariableInfo.css";
const VariableInfo = (_, ref) => (
  <div
    ref={ref}
    className="info-overlay"
    style={{ display: "none" }}
    onClick={() => {
      ref.current.style.display = "none";
    }}
  >
    <aside className="variable-desc rounded shadow">
      <h2>Variables</h2>
      <div className="var-grid">
        <div className="var-card">
          <img
            src={VotersIcon}
            alt="Icono electores."
            style={{ color: "white", height: "80px" }}
          />
          <h3>Electores</h3>
          <p>
            Cantidad de personas inscritas en el Servicio Electoral de Chile que
            se encuentran habilitadas para sufragar.
          </p>
        </div>
        <div className="var-card">
          <img
            src={ElectorsIcon}
            alt="Icono votantes."
            style={{ color: "white", height: "80px" }}
          />
          <h3>Votantes</h3>
          <p>Cantidad de personas que votan o emiten su voto.</p>
        </div>
        <div className="var-card">
          <img
            src={VoteIcon}
            alt="Icono participación"
            style={{ color: "white", height: "80px" }}
          />
          <h3>Participación Electoral</h3>
          <p>
            Nivel de participación de un proceso determinado. Se calcula de la
            siguiente forma:
          </p>
          <MathComponent
            tex={String.raw`100 \times \frac{cantidad\;de\;votantes}{cantidad\;de\;electores}`}
          />
        </div>
      </div>
    </aside>
  </div>
);

export default forwardRef(VariableInfo);
