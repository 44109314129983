import {
  LOAD_COMUNAS_LAYER,
  LOAD_LAYERS,
  LOAD_LOCALES_LAYER,
  LOAD_MESAS_DATA,
  LOAD_REGIONES_LAYER,
} from "../actions/types";

const initialState = {
  regiones: null,
  comunas: null,
  locales: null,
  mesas: null,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_REGIONES_LAYER:
      return { ...state, regiones: payload };
    case LOAD_COMUNAS_LAYER:
      return { ...state, comunas: payload };
    case LOAD_LOCALES_LAYER:
      return { ...state, locales: payload };
    case LOAD_MESAS_DATA:
      return { ...state, mesas: payload };
    case LOAD_LAYERS:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default reducer;
