import { turnoutD3ColorScale } from "./ColorScales";
import _ from "lodash";
// filters

export const defaultElectionFilter = [
  "all",
  ["==", ["get", "Eleccion"], "Constituyentes 2021"],
];

//  color scales

export const turnoutColorScale = [
  "case",
  ["==", ["typeof", ["get", "votantes"]], "number"],
  [
    "interpolate",
    ["linear"],
    ["/", ["get", "votantes"], ["get", "total"]],
    ..._.range(0, 1.1, 0.1)
      .map((br) => [br, turnoutD3ColorScale(br)])
      .flat(),
  ],
  "#474747",
];

export const etapaColorScale = [
  "case",
  ["==", ["typeof", ["get", "Etapa_plan"]], "number"],
  [
    "case",
    ["boolean", ["==", ["number", ["get", "Etapa_plan"]], 1.0]],
    "#F75C5C",
    ["boolean", ["==", ["number", ["get", "Etapa_plan"]], 2.0]],
    "#FFBF00",
    ["boolean", ["==", ["number", ["get", "Etapa_plan"]], 3.0]],
    "#FFF200",
    ["boolean", ["==", ["number", ["get", "Etapa_plan"]], 4.0]],
    "#338AD1",
    ["boolean", ["==", ["number", ["get", "Etapa_plan"]], 5.0]],
    "#EFF0F0",
    "#474747",
  ],
  "#474747",
];

// circle sizes

export const localesCircleRadius = [
  "interpolate",
  ["linear"],
  ["zoom"],
  4,
  ["/", ["number", ["get", "Electores"]], 4000],
  6,
  ["/", ["number", ["get", "Electores"]], 3000],
  14,
  ["/", ["number", ["get", "Electores"]], 800],
];

export const localesCircleStrokeWidth = [
  "case",
  ["boolean", ["feature-state", "selected"], false],
  3,
  1,
];

export const polygonsOutlineColor = [
  "case",
  ["boolean", ["feature-state", "selected"], false],
  "#777",
  "#bbb",
];

export const polygonsOutlineWidth = [
  "case",
  ["boolean", ["feature-state", "selected"], false],
  2,
  ["case", ["boolean", ["feature-state", "hovered"], false], 2, 0],
];

export const getGeographicFilter = (filters, selectedLayer) => {
  const mapboxFilters = ["all"];
  if (filters.selectedRegion !== "all") {
    const mapboxRegionFilter = [
      "==",
      ["get", "NOM_REGION"],
      filters.selectedRegion,
    ];
    mapboxFilters.push(mapboxRegionFilter);
  }

  if (filters.selectedCommune !== "all" && selectedLayer !== "regiones-layer") {
    const mapboxCommuneFilter = [
      "==",
      ["get", "NOM_COMUNA"],
      filters.selectedCommune,
    ];
    mapboxFilters.push(mapboxCommuneFilter);
  }

  if (
    filters.selectedLocal !== "all" &&
    selectedLayer !== "regiones-layer" &&
    selectedLayer !== "comunas-layer"
  ) {
    const mapboxLocalFilter = ["==", ["get", "Local"], filters.selectedLocal];
    mapboxFilters.push(mapboxLocalFilter);
  }
  return mapboxFilters;
};
