import axios from "axios";
import { LOAD_PARTICIPATION_DATA } from "./types";

export const loadData = () => (dispatch) => {
  Promise.all([
    //axios.get("https://nodejs.isci.cl/elecciones/records.json"),
    axios.get("https://nodejs.isci.cl/elecciones/region_bounds.json"),
    axios.get("https://nodejs.isci.cl/elecciones/comunas_bounds.json"),
  ]).then((result) =>
    dispatch({
      type: LOAD_PARTICIPATION_DATA,
      payload: result.map((res) => res.data),
    })
  );
};
