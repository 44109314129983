import "./LocationTooltip.css";
const LocationTooltip = ({ location }) => {
  return location ? (
    <aside className="location-tooltip rounded shadow">
      <p>{location}</p>
    </aside>
  ) : null;
};

export default LocationTooltip;
