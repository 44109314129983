import { useEffect } from "react";
import _ from "lodash";
import Selector from "../Selector/Selector";

import "./FiltersContent.css";

const FiltersContent = ({
  selectedLayer,
  layers,
  filters,
  setFilters,
  election,
}) => {
  useEffect(() => {
    const mapboxFilters = ["all"];
    if (filters.selectedRegion !== "all") {
      const mapboxRegionFilter = [
        "==",
        ["get", "NOM_REGION"],
        filters.selectedRegion,
      ];
      mapboxFilters.push(mapboxRegionFilter);
    }

    if (filters.selectedCommune !== "all") {
      const mapboxCommuneFilter = [
        "==",
        ["get", "NOM_COMUNA"],
        filters.selectedCommune,
      ];
      mapboxFilters.push(mapboxCommuneFilter);
    }

    if (filters.selectedLocal !== "all") {
      const mapboxLocalFilter = ["==", ["get", "Local"], filters.selectedLocal];
      mapboxFilters.push(mapboxLocalFilter);
    }
  });
  useEffect(() => {
    const data = layers[selectedLayer.replace("-layer", "")].features.filter(
      (d) => d.properties["Eleccion"] === election
    );
    const regionsList = _.uniq(data.map((d) => d.properties["NOM_REGION"])); // get unique regions

    setFilters({ ...filters, regions: regionsList });
    // eslint-disable-next-line
  }, []);

  const handleRegionChange = ({ target }) => {
    const selectedRegion = target.value;
    if (selectedRegion !== "all") {
      const data = layers["comunas"].features;
      const communesList = _.uniq(
        data
          .filter(
            (d) =>
              d.properties["NOM_REGION"] === selectedRegion &&
              d.properties["Eleccion"] === election
          )
          .map((d) => d.properties["NOM_COMUNA"])
      );
      setFilters({
        ...filters,
        communes: communesList,
        selectedRegion,
        selectedCommune: "all",
        selectedLocal: "all",
      });
    } else {
      setFilters({
        ...filters,
        communes: [],
        locales: [],
        selectedRegion: "all",
        selectedCommune: "all",
        selectedLocal: "all",
      });
    }
  };

  const handleCommuneChange = ({ target }) => {
    const selectedCommune = target.value;
    if (selectedCommune) {
      const data = layers["locales"].features;
      const localesList = _.uniq(
        data
          .filter(
            (d) =>
              d.properties["NOM_COMUNA"] === selectedCommune &&
              d.properties["Eleccion"] === election
          )
          .map((d) => d.properties["Local"])
      );
      setFilters({
        ...filters,
        selectedCommune,
        selectedLocal: "all",
        locales: localesList,
      });
    } else {
      setFilters({
        ...filters,
        selectedCommune: "all",
        selectedLocal: "all",
        communes: [],
      });
    }
  };

  const handleLocalChange = ({ target }) => {
    const selectedLocal = target.value;
    setFilters({
      ...filters,
      selectedLocal: selectedLocal,
    });
  };

  return (
    <>
      <h2>Filtros</h2>
      <div className="filters">
        <ul>
          <li className="filter">
            <label htmlFor="region-selector">Región</label>
            <Selector
              id="region-selector"
              onChange={handleRegionChange}
              value={filters.selectedRegion}
            >
              <option value={"all"}>Todas</option>
              {filters.regions.map((r) => (
                <option value={r} key={r}>
                  {r}
                </option>
              ))}
            </Selector>
          </li>

          {filters.selectedRegion !== "all"
            ? selectedLayer !== "regiones-layer" && (
                <li className="filter">
                  <label htmlFor="commune-selector">Comuna</label>
                  <Selector
                    id="commune-selector"
                    value={filters.selectedCommune}
                    onChange={handleCommuneChange}
                  >
                    <option value="all">Todas</option>
                    {filters.communes.map((r) => (
                      <option value={r} key={r}>
                        {r}
                      </option>
                    ))}
                  </Selector>
                </li>
              )
            : null}

          {filters.selectedCommune !== "all" &&
          selectedLayer !== "regiones-layer" &&
          selectedLayer !== "comunas-layer" ? (
            <li className="filter">
              <label htmlFor="local-selector">Local</label>
              <Selector
                id="local-selector"
                value={filters.selectedLocal}
                onChange={handleLocalChange}
              >
                <option value="all">Todos</option>
                {filters.locales.map((r) => (
                  <option value={r} key={r}>
                    {r}
                  </option>
                ))}
              </Selector>
            </li>
          ) : null}
        </ul>
      </div>
    </>
  );
};

export default FiltersContent;
