import * as d3 from "d3";
import _ from "lodash";
export const turnoutD3ColorScale = d3.interpolateRdYlGn;

const variableMap = {
  participacion: "participacion",
  total: "electores",
  votantes: "votantes",
};

export const createCustomColorScale = (data, key, type = "number") => {
  const variableGetter =
    key === "participacion"
      ? ["/", ["get", "votantes"], ["get", "total"]]
      : ["get", key];

  if (type === "percentage")
    return [
      [
        "case",
        ["==", ["typeof", variableGetter], "number"],
        [
          "interpolate",
          ["linear"],
          variableGetter,
          ..._.range(0, 1.1, 0.1)
            .map((br) => [br, turnoutD3ColorScale(br)])
            .flat(),
        ],
        "#474747",
      ],
      0,
      1,
    ];
  const minData = _.min(data.map((d) => d[variableMap[key]]));
  const maxData = _.max(data.map((d) => d[variableMap[key]]));

  const step = (maxData - minData) / 10;

  const customColorScale = [
    "case",
    ["==", ["typeof", ["get", key]], "number"],
    [
      "interpolate",
      ["linear"],
      variableGetter,
      ..._.range(minData, maxData + step, step)
        .map((br) => [br, turnoutD3ColorScale((br - minData) / maxData)])
        .flat(),
    ],
    "#474747",
  ];

  return [customColorScale, minData, maxData];
};
