import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

import "./Console.css";

import FiltersContent from "../FiltersContent/FiltersContent";
import LayersContent from "../LayersContent/LayersContent";
import EtapaLegend from "../EtapaLegend/EtapaLegend";
import {
  faArrowDown,
  faLayerGroup,
  faFilter,
  faCogs,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getGeographicFilter } from "../../helpers/MapboxExpressions";
import useZoomOnChangeFilter from "../../hooks/useZoomOnChangeFilter";
import useWindowDimensions from "../../hooks/useWindowDimensions";
const Console = ({
  mapActive,
  election,
  setElection,
  map,
  setSelectedFeature,
  selectedFeature,
  layers,
  regionBounds,
  comunasBounds,
  selectedVariable,
  setSelectedVariable,
  infoVariable,
}) => {
  const consoleContainer = useRef(null);
  const [activePanel, setActivePanel] = useState("layers");
  const [consoleOpen, setConsoleOpen] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState("regiones-layer");
  const etapaLegend = useRef(null);
  const { width, height } = useWindowDimensions();
  const [filters, setFilters] = useState({
    selectedRegion: "all",
    selectedCommune: "all",
    selectedLocal: "all",
    regions: [],
    communes: [],
    locales: [],
  });

  useEffect(() => {
    if (!map.current || !mapActive) return;
    // geographic filter
    const geographicFilter = getGeographicFilter(filters);
    // election filter
    const electionFilter = ["==", ["get", "eleccion"], election];
    /*
    map.current.setFilter("locales-layer", [
      "all",
      electionFilter,
      geographicFilter,
    ]);
    */
    /*
    map.current.setFilter("etapa-layer", [
      "all",
      electionFilter,
      geographicFilter.slice(0, 2),
    ]);
    */
    map.current.setFilter("comunas-layer", [
      "all",
      electionFilter,
      geographicFilter,
    ]);
    map.current.setFilter("comunas-outline-layer", [
      "all",
      electionFilter,
      geographicFilter,
    ]);
    map.current.setFilter("regiones-layer", [
      "all",
      electionFilter,
      geographicFilter,
    ]);
    map.current.setFilter("regiones-outline-layer", [
      "all",
      electionFilter,
      geographicFilter,
    ]);
  }, [filters, map, setSelectedFeature, election, mapActive]);

  useZoomOnChangeFilter(
    map,
    filters,
    election,
    setSelectedFeature,
    regionBounds,
    comunasBounds,
    width,
    height
  );
  return (
    <aside
      ref={consoleContainer}
      className={
        mapActive ? "console rounded active shadow" : "console rounded shadow"
      }
    >
      <header>
        <div
          className="menu-header"
          onClick={() => {
            consoleContainer.current.classList.toggle("open");
            setConsoleOpen(!consoleOpen);
          }}
        >
          <h2>Menú</h2>
          <div className="settings-icon">
            <FontAwesomeIcon icon={faCogs} />
          </div>
          <div className="arrow">
            <FontAwesomeIcon
              icon={faArrowDown}
              className={consoleOpen ? "rotate" : ""}
            />
          </div>
        </div>
        <div className="break"></div>
        <nav className="console-navigation" style={{ display: "none" }}>
          <ul>
            <li
              className={activePanel === "layers" ? "active" : null}
              onClick={() => setActivePanel("layers")}
            >
              <FontAwesomeIcon icon={faLayerGroup} />
            </li>
            <li
              className={activePanel === "filters" ? "active" : null}
              onClick={() => setActivePanel("filters")}
            >
              <FontAwesomeIcon icon={faFilter} />
            </li>
          </ul>
        </nav>
      </header>
      <div className="content">
        {activePanel === "layers" ? (
          <LayersContent
            map={map}
            mapActive={mapActive}
            election={election}
            setElection={setElection}
            etapaLegend={etapaLegend}
            layers={layers}
            selectedLayer={selectedLayer}
            setSelectedLayer={setSelectedLayer}
            filters={filters}
            setFilters={setFilters}
            selectedVariable={selectedVariable}
            setSelectedVariable={setSelectedVariable}
            infoVariable={infoVariable}
          />
        ) : (
          <FiltersContent
            selectedLayer={selectedLayer}
            layers={layers}
            filters={filters}
            setFilters={setFilters}
            election={election}
          />
        )}
      </div>
      <EtapaLegend ref={etapaLegend} />
    </aside>
  );
};

const mapStateToProps = (state) => ({
  layers: state.layers,
  selectedFeature: state.mapState.selectedFeature,
  regionBounds: state.data.regionBounds,
  comunasBounds: state.data.comunasBounds,
});

export default connect(mapStateToProps, {})(Console);
