import { useEffect } from "react";
import {
  // defaultElectionFilter,
  // etapaColorScale,
  polygonsOutlineColor,
  polygonsOutlineWidth,
  turnoutColorScale,
} from "../helpers/MapboxExpressions";

const useComunasLayer = (
  map,
  setSelectedFeature,
  setLocation,
  comunasBounds,
  width,
  height
) => {
  useEffect(() => {
    if (!map.current) return;
    let hoveredPolygon;

    map.current.on("load", () => {
      map.current.addSource("comunas", {
        type: "geojson",
        data: "https://nodejs.isci.cl/elecciones/comunas_servel.geojson",
        generateId: true,
      });
      /*
      map.current.addLayer(
        {
          id: "etapa-layer",
          type: "fill",
          source: "comunas",
          layout: {
            visibility: "none",
          },
          paint: {
            "fill-outline-color": "#fff",
            "fill-color": etapaColorScale,
            "fill-opacity-transition": {
              duration: 450,
            },
            "fill-opacity": 0.3,
          },
          filter: defaultElectionFilter,
        },
        "waterway"
      );
      */
      map.current.addLayer({
        id: "comunas-outline-layer",
        type: "line",
        source: "comunas",
        layout: {
          visibility: "visible",
        },
        paint: {
          "line-color": polygonsOutlineColor,
          "line-width": polygonsOutlineWidth,
        },
      });

      map.current.addLayer(
        {
          id: "comunas-layer",
          type: "fill",
          source: "comunas",
          layout: {
            visibility: "none",
          },
          paint: {
            "fill-outline-color": "#fff",
            "fill-opacity-transition": {
              duration: 450,
            },
            "fill-color": turnoutColorScale,
          },
        },
        "waterway"
      );
    });

    map.current.on("mousemove", "comunas-layer", ({ features }) => {
      map.current.getCanvas().style.cursor = "pointer";
      if (features.length > 0) {
        if (hoveredPolygon !== null && hoveredPolygon !== undefined) {
          map.current.setFeatureState(
            { source: "comunas", id: hoveredPolygon },
            { hovered: false }
          );
        }
        hoveredPolygon = features[0].id;
        map.current.setFeatureState(
          { source: "comunas", id: hoveredPolygon },
          { hovered: true }
        );

        setLocation(
          `${features[0].properties["NOM_COMUNA"]}, ${features[0].properties["NOM_REGION"]}`
        );
      }
    });

    map.current.on("mouseleave", "comunas-layer", ({ features }) => {
      map.current.getCanvas().style.cursor = "";
      if (hoveredPolygon !== null && hoveredPolygon !== undefined) {
        map.current.setFeatureState(
          { source: "comunas", id: hoveredPolygon },
          { hovered: false }
        );
        setLocation(null);
      }
    });

    if (!map.current) return;
    let selectedPolygon;
    map.current.on("click", "comunas-layer", (e) => {
      e.preventDefault();
      const { features } = e;

      if (features.length > 0) {
        if (selectedPolygon !== null && selectedPolygon !== undefined) {
          map.current.setFeatureState(
            { source: "comunas", id: selectedPolygon },
            { selected: false }
          );
        }

        selectedPolygon = features[0].id;
        setSelectedFeature(features[0]);

        map.current.setFeatureState(
          { source: "comunas", id: selectedPolygon },
          { selected: true }
        );
      }
      const { minx, miny, maxx, maxy } =
        comunasBounds[features[0].properties["NOM_COMUNA"]];

      map.current.fitBounds(
        [
          [minx, miny],
          [maxx, maxy],
        ],
        {
          padding: {
            top: width < 1000 ? height * 0.2 : 200,
            bottom: width < 1000 ? height * 0.2 : 200,
            left: width < 1000 ? width * 0.2 : 200,
            right: width < 1000 ? width * 0.2 : 200,
          },
        }
      );
    });

    map.current.on("click", (e) => {
      if (e.defaultPrevented === false) {
        if (selectedPolygon !== null && selectedPolygon !== undefined) {
          map.current.setFeatureState(
            { source: "comunas", id: selectedPolygon },
            { selected: false }
          );
          setSelectedFeature(null);
        }
      }
    });
  }, [map, setSelectedFeature, setLocation, comunasBounds, width, height]);
};

export default useComunasLayer;
