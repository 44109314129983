import { useRef, useEffect } from "react";
import "./Selector.css";

const Selector = ({ children, ...props }) => {
  const select = useRef(null);
  const arrowContainer = useRef(null);
  useEffect(() => {
    select.current.addEventListener("click", function () {
      arrowContainer.current.classList.toggle("active");
    });
  });
  return (
    <div className="select-group">
      <div className="select-container" ref={arrowContainer}>
        <select className="rounded" {...props} ref={select}>
          {children}
        </select>
      </div>
    </div>
  );
};

export default Selector;
