import "./Loader.css";

const Loader = () => (
    <div className="lds-ripple">
        <div></div>
        <div></div>
    </div>
);

export default Loader;
