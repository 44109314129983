import { numberWithCommas } from "../helpers/StringFormatting";
export const layerVariables = {
  mesas: [
    {
      key: "Turnout",
      name: "Participación",
      type: "number",
      class: "election",
      tickFormatter: (d) => (d * 100).toFixed(0) + "%",
      defaultX: false,
      defaultY: true,
    },
    {
      key: "Electores",
      name: "Electores",
      type: "number",
      class: "election",
      tickFormatter: numberWithCommas,
      defaultX: false,
      defaultY: false,
    },
    {
      key: "Votantes",
      name: "Votantes",
      type: "number",
      class: "election",
      tickFormatter: numberWithCommas,
      defaultX: false,
      defaultY: false,
    },
    {
      key: "Tipo",
      name: "Tipo",
      type: "category",
      class: "election",
      tickFormatter: null,
      defaultX: true,
      defaultY: false,
    },
  ],
  locales: [
    {
      key: "participacion",
      name: "Participación",
      type: "number",
      class: "election",
      tickFormatter: (d) => (d * 100).toFixed(0) + "%",
      defaultX: false,
      defaultY: true,
    },
    {
      key: "total",
      name: "Electores",
      type: "number",
      class: "election",
      tickFormatter: numberWithCommas,
      defaultX: false,
      defaultY: false,
    },
    {
      key: "votantes",
      name: "Votantes",
      type: "number",
      class: "election",
      tickFormatter: numberWithCommas,
      defaultX: false,
      defaultY: false,
    },
    /*
    {
      key: "CasosNuevos100_2",
      name: "Contagios",
      type: "number",
      class: "covid",
      tickFormatter: (d) => d.toFixed(0),
      defaultX: true,
      defaultY: false,
    },
    {
      key: "Fallecidos100_2",
      name: "Fallecidos",
      type: "number",
      class: "covid",
      tickFormatter: (d) => d.toFixed(0),
      defaultX: false,
      defaultY: false,
    },
    {
      key: "Etapa_plan",
      name: "Etapa",
      type: "number",
      class: "election",
      tickFormatter: null,
      defaultX: false,
      defaultY: false,
    },
    {
      key: "PrimeraDosis",
      name: "Vacunados Primera Dosis",
      type: "number",
      class: "covid",
      tickFormatter: (d) => d.toFixed(0),
      defaultX: false,
      defaultY: false,
    },
    {
      key: "Escolaridad",
      name: "Escolaridad",
      type: "number",
      class: "censo",
      tickFormatter: (d) => d.toFixed(1),
      defaultX: false,
      defaultY: false,
    },
    {
      key: "Población",
      name: "Poblacion",
      type: "number",
      class: "censo",
      tickFormatter: (d) => d.toFixed(0),
      defaultX: false,
      defaultY: false,
    },*/
  ],

  comunas: [
    {
      key: "participacion",
      name: "Participación",
      type: "number",
      class: "election",
      tickFormatter: (d) => (d * 100).toFixed(0) + "%",
      defaultX: false,
      defaultY: true,
    },
    {
      key: "total",
      name: "Electores",
      type: "number",
      class: "election",
      tickFormatter: numberWithCommas,
      defaultX: false,
      defaultY: false,
    },
    {
      key: "votantes",
      name: "Votantes",
      type: "number",
      class: "election",
      tickFormatter: numberWithCommas,
      defaultX: false,
      defaultY: false,
    },
    /*
    {
      key: "CasosNuevos100_2",
      name: "Contagios",
      type: "number",
      class: "covid",
      tickFormatter: (d) => d.toFixed(0),
      defaultX: true,
      defaultY: false,
    },
    {
      key: "Fallecidos100_2",
      name: "Fallecidos",
      type: "number",
      class: "covid",
      tickFormatter: (d) => d.toFixed(0),
      defaultX: false,
      defaultY: false,
    },
    {
      key: "Etapa_plan",
      name: "Etapa",
      type: "number",
      class: "election",
      tickFormatter: null,
      defaultX: false,
      defaultY: false,
    },
    {
      key: "PrimeraDosis",
      name: "Vacunados Primera Dosis",
      type: "number",
      class: "covid",
      tickFormatter: (d) => d.toFixed(0),
      defaultX: false,
      defaultY: false,
    },
    {
      key: "SegundaDosis",
      name: "Vacunados Segunda Dosis",
      type: "number",
      class: "covid",
      tickFormatter: (d) => d.toFixed(0),
      defaultX: false,
      defaultY: false,
    },
    {
      key: "Escolaridad",
      name: "Escolaridad",
      type: "number",
      class: "censo",
      tickFormatter: (d) => d.toFixed(0) + "%",
      defaultX: false,
      defaultY: false,
    },
    {
      key: "Poblacion",
      name: "Población",
      type: "number",
      class: "censo",
      tickFormatter: (d) => d.toFixed(0),
      defaultX: false,
      defaultY: false,
    },
    {
      key: "PobrezaM",
      name: "Pobreza",
      type: "number",
      class: "censo",
      tickFormatter: (d) => d.toFixed(0) + "%",
      defaultX: false,
      defaultY: false,
    },
    */
  ],
  regiones: [
    {
      key: "participacion",
      name: "Participación",
      type: "number",
      class: "election",
      tickFormatter: (d) => (d * 100).toFixed(0) + "%",
      defaultX: false,
      defaultY: true,
    },
    {
      key: "total",
      name: "Electores",
      type: "number",
      class: "election",
      tickFormatter: numberWithCommas,
      defaultX: false,
      defaultY: false,
    },
    {
      key: "votantes",
      name: "Votantes",
      type: "number",
      class: "election",
      tickFormatter: numberWithCommas,
      defaultX: false,
      defaultY: false,
    } /*
    {
      key: "CasosNuevos100_2",
      name: "Contagios",
      type: "number",
      class: "covid",
      tickFormatter: (d) => d.toFixed(0),
      defaultX: true,
      defaultY: false,
    },
    {
      key: "Fallecidos100_2",
      name: "Fallecidos",
      type: "number",
      class: "covid",
      tickFormatter: (d) => d.toFixed(0),
      defaultX: false,
      defaultY: false,
    },
    {
      key: "Etapa_plan",
      name: "Etapa",
      type: "number",
      class: "election",
      tickFormatter: null,
      defaultX: false,
      defaultY: false,
    },
    {
      key: "PrimeraDosis",
      name: "Vacunados Primera Dosis",
      type: "number",
      class: "covid",
      tickFormatter: (d) => d.toFixed(0),
      defaultX: false,
      defaultY: false,
    },
    {
      key: "SegundaDosis",
      name: "Vacunados Segunda Dosis",
      type: "number",
      class: "covid",
      tickFormatter: (d) => d.toFixed(0),
      defaultX: false,
      defaultY: false,
    },
    {
      key: "Escolaridad",
      name: "Escolaridad",
      type: "number",
      class: "censo",
      tickFormatter: (d) => d.toFixed(0) + "%",
      defaultX: false,
      defaultY: false,
    },
    {
      key: "Poblacion",
      name: "Población",
      type: "number",
      class: "censo",
      tickFormatter: (d) => d.toFixed(0),
      defaultX: false,
      defaultY: false,
    },
    {
      key: "PobrezaM",
      name: "Pobreza",
      type: "number",
      class: "censo",
      tickFormatter: (d) => d.toFixed(0) + "%",
      defaultX: false,
      defaultY: false,
    },*/,
  ],
};
