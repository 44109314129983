import { ANIMATION_DURATION, ANIMATION_DELAY } from "../config/animation";

export const hideLayer = (map, layerId) => {
  const { type } = map.getLayer(layerId);
  switch (type) {
    case "circle":
      map.setPaintProperty(layerId, "circle-opacity", 0);
      map.setPaintProperty(layerId, "circle-stroke-opacity", 0);
      setTimeout(function () {
        map.setLayoutProperty(layerId, "visibility", "none");
      }, ANIMATION_DURATION);
      break;

    case "fill":
      map.setPaintProperty(layerId, "fill-opacity", 0);
      setTimeout(function () {
        map.setLayoutProperty(layerId, "visibility", "none");
      }, ANIMATION_DURATION);
      break;

    default:
      break;
  }
};

export const showLayer = (map, layerId, opacity = 1) => {
  const { type } = map.getLayer(layerId);
  switch (type) {
    case "circle":
      map.setLayoutProperty(layerId, "visibility", "visible");
      setTimeout(function () {
        map.setPaintProperty(layerId, "circle-opacity", opacity);
        map.setPaintProperty(layerId, "circle-stroke-opacity", opacity);
      }, ANIMATION_DELAY);
      break;

    case "fill":
      map.setLayoutProperty(layerId, "visibility", "visible");
      setTimeout(function () {
        map.setPaintProperty(layerId, "fill-opacity", opacity);
      }, ANIMATION_DELAY);
      break;

    default:
      break;
  }
};
