import { useEffect } from "react";
import {
  defaultElectionFilter,
  polygonsOutlineColor,
  polygonsOutlineWidth,
  turnoutColorScale,
} from "../helpers/MapboxExpressions";

const useRegionesLayer = (
  map,
  setSelectedFeature,
  setLocation,
  regionBounds,
  width,
  height
) => {
  useEffect(() => {
    // define regiones layer
    if (!map.current) return;

    let selectedPolygon;
    let hoveredPolygon;

    map.current.on("load", () => {
      map.current.addSource("regiones", {
        type: "geojson",
        data: "https://nodejs.isci.cl/elecciones/regiones_servel.geojson",
        generateId: true,
      });
      map.current.addLayer(
        {
          id: "regiones-layer",
          type: "fill",
          source: "regiones",
          layout: {
            visibility: "visible",
          },
          paint: {
            "fill-outline-color": "#fff",
            "fill-opacity-transition": {
              duration: 450,
            },
            "fill-color": turnoutColorScale,
          },
        },
        "waterway"
      );
      map.current.addLayer({
        id: "regiones-outline-layer",
        type: "line",
        source: "regiones",
        layout: {
          visibility: "visible",
        },
        paint: {
          "line-color": polygonsOutlineColor,
          "line-width": polygonsOutlineWidth,
        },
        filter: defaultElectionFilter,
      });

      map.current.on("mousemove", "regiones-layer", ({ features }) => {
        map.current.getCanvas().style.cursor = "pointer";
        if (features.length > 0) {
          if (hoveredPolygon !== null && hoveredPolygon !== undefined) {
            map.current.setFeatureState(
              { source: "regiones", id: hoveredPolygon },
              { hovered: false }
            );
          }
          hoveredPolygon = features[0].id;
          map.current.setFeatureState(
            { source: "regiones", id: hoveredPolygon },
            { hovered: true }
          );
          setLocation(features[0].properties["NOM_REGION"]);
        }
      });

      map.current.on("mouseleave", "regiones-layer", () => {
        map.current.getCanvas().style.cursor = "";
        if (hoveredPolygon !== null && hoveredPolygon !== undefined) {
          map.current.setFeatureState(
            { source: "regiones", id: hoveredPolygon },
            { hovered: false }
          );
          setLocation(null);
        }
      });

      map.current.on("click", "regiones-layer", (e) => {
        e.preventDefault();

        const { features } = e;

        if (features.length > 0) {
          if (selectedPolygon !== null && selectedPolygon !== undefined) {
            map.current.setFeatureState(
              { source: "regiones", id: selectedPolygon },
              { selected: false }
            );
          }

          selectedPolygon = features[0].id;

          map.current.setFeatureState(
            { source: "regiones", id: selectedPolygon },
            { selected: true }
          );

          const { minx, miny, maxx, maxy } =
            regionBounds[features[0].properties["NOM_REGION"]];

          map.current.fitBounds(
            [
              [minx, miny],
              [maxx, maxy],
            ],
            {
              padding: {
                top: width < 1000 ? height * 0.2 : 200,
                bottom: width < 1000 ? height * 0.2 : 200,
                left: width < 1000 ? width * 0.2 : 200,
                right: width < 1000 ? width * 0.2 : 200,
              },
            }
          );

          setSelectedFeature(features[0]);
        }
      });
      map.current.on("click", (e) => {
        if (e.defaultPrevented === false) {
          if (selectedPolygon !== null && selectedPolygon !== undefined) {
            map.current.setFeatureState(
              { source: "regiones", id: selectedPolygon },
              { selected: false }
            );
            setSelectedFeature(null);
          }
        }
      });
    });
  }, [map, setSelectedFeature, setLocation, regionBounds, width, height]);
};

export default useRegionesLayer;
