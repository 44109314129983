import { useEffect } from "react";

const useZoomOnChangeFilter = (
  map,
  filters,
  election,
  setSelectedFeature,
  regionBounds,
  comunasBounds,
  width,
  height
) => {
  useEffect(() => {
    if (
      filters.selectedRegion &&
      filters.selectedRegion !== "all" &&
      filters.selectedCommune === "all"
    ) {
      map.current.once("moveend", () => {
        const features = map.current.queryRenderedFeatures({
          filter: [
            "all",
            ["==", ["get", "eleccion"], election],
            ["==", ["get", "NOM_REGION"], filters.selectedRegion],
          ],
          layers: ["regiones-layer"],
        });

        setSelectedFeature(features[0]);
      });

      const { minx, miny, maxx, maxy } = regionBounds[filters.selectedRegion];

      map.current.fitBounds(
        [
          [minx, miny],
          [maxx, maxy],
        ],
        {
          padding: {
            top: width < 1000 ? height * 0.2 : 200,
            bottom: width < 1000 ? height * 0.2 : 200,
            left: width < 1000 ? width * 0.2 : 200,
            right: width < 1000 ? width * 0.2 : 200,
          },
        }
      );
    } else if (filters.selectedCommune && filters.selectedCommune !== "all") {
      map.current.once("moveend", () => {
        const features = map.current.queryRenderedFeatures({
          filter: [
            "all",
            ["==", ["get", "eleccion"], election],
            ["==", ["get", "NOM_REGION"], filters.selectedRegion],
            ["==", ["get", "NOM_COMUNA"], filters.selectedCommune],
          ],
          layers: ["comunas-layer"],
        });

        setSelectedFeature(features[0]);
      });

      const { minx, miny, maxx, maxy } = comunasBounds[filters.selectedCommune];

      map.current.fitBounds(
        [
          [minx, miny],
          [maxx, maxy],
        ],
        {
          padding: 300,
        }
      );
    }
  }, [
    election,
    filters,
    map,
    setSelectedFeature,
    regionBounds,
    comunasBounds,
    width,
    height,
  ]);
};

export default useZoomOnChangeFilter;
