import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import { rollups } from "d3";
import * as d3 from "d3";
import { connect } from "react-redux";
import { numberWithCommas, toCamelCase } from "../../helpers/StringFormatting";
import Loader from "../Loader/Loader";
import "./GenderAgeParticipation.css";

const variableMap = {
  participacion: "participacion",
  total: "electores",
  votantes: "votantes",
};

const GenderAgeParticipation = ({ selectedFeature, selectedVariable }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const election = selectedFeature.properties["eleccion"];

  const reduceVar = variableMap[selectedVariable];
  useEffect(() => {
    const { layer } = selectedFeature;
    setLoading(true);
    axios
      .get("https://nodejs.isci.cl/visor-elecciones/records", {
        params: {
          filters: {
            ...(layer.source !== "country" && {
              NOM_REGION: selectedFeature.properties["NOM_REGION"],
            }),
            ...(layer.id === "comunas-layer" && {
              NOM_COMUNA: selectedFeature.properties["NOM_COMUNA"],
            }),
            eleccion: election,
          },
          group: ["edad", "sexo"],
        },
      })
      .then((response) => {
        const rollup = rollups(
          response.data,
          (v) =>
            v.reduce(
              (acc, d) => ({
                [d.sexo]: d.sexo === "femenino" ? -d[reduceVar] : d[reduceVar],
                [`electores_${d.sexo}`]: d.electores,
                ...acc,
              }),
              {}
            ),
          (d) => d.edad
        )
          .sort((a, b) => -Number(a[0].slice(0, 2)) + Number(b[0].slice(0, 2)))
          .map((d) => ({ edad: d[0], ...d[1] }));
        setData(rollup);
        setLoading(false);
      });
  }, [selectedFeature.properties, election, selectedFeature, reduceVar]);

  if (data.length > 0) {
    return (
      <div className="chart-container rounded">
        {loading ? <Loader></Loader> : null}

        <h3>
          {toCamelCase(
            selectedVariable === "total"
              ? "electores"
              : selectedVariable.replace("participacion", "participación")
          )}{" "}
          por Sexo y Edad
        </h3>
        <div className="chart-container-dem">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={400}
              height={300}
              data={data}
              layout="vertical"
              barGap={0}
              margin={{
                top: 10,
                right: 10,
                bottom: 10,
                left: 10,
              }}
            >
              <XAxis
                type="number"
                tickFormatter={
                  selectedVariable === "participacion"
                    ? (d) => `${(Math.abs(d) * 100).toFixed(0)}%`
                    : (d) => `${d3.format(".2s")(Math.abs(d))}`
                }
              />
              <YAxis
                datakey="edad"
                type="category"
                width={40}
                interval={0}
                ticks={_.range(0, data.length)}
                tickFormatter={(d) =>
                  data.length > 0
                    ? data[d].edad.toLocaleString().replace(/ /g, "\u00A0")
                    : null
                }
              />

              <Tooltip
                labelFormatter={(d) =>
                  data.length > 0
                    ? data[d].edad.replace("-119", "+") + " años"
                    : null
                }
                formatter={(value, name, props) => {
                  const electores =
                    props.payload[`electores_${props["dataKey"]}`];

                  return selectedVariable !== "total"
                    ? [
                        `${
                          selectedVariable === "participacion"
                            ? (Math.abs(value) * 100).toFixed(1) + "%"
                            : d3.format(".2s")(Math.abs(value))
                        } de ${numberWithCommas(electores)} electores`,
                        name,
                      ]
                    : [`${d3.format(".2s")(Math.abs(value))} electores`, name];
                }}
              />
              <Legend />
              <Bar
                dataKey="femenino"
                fill="#e29578"
                barSize={8}
                transform={`translate(0, 3)`}
                radius={[0, 3, 3, 0]}
              />

              <Bar
                dataKey="masculino"
                fill="#006d77"
                barSize={8}
                transform={`translate(0, -3)`}
                radius={[0, 3, 3, 0]}
              />
              <ReferenceLine x={0} stroke="rgb(102, 102, 102)" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
  return (
    <div className="chart-container rounded">
      <h3>
        {toCamelCase(
          selectedVariable === "total" ? "electores" : selectedVariable
        )}{" "}
        por Edad y Sexo
      </h3>
      <p>Sin Datos</p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  participationData: state.data.participationData,
  selectedFeature: state.mapState.selectedFeature,
});
export default connect(mapStateToProps, {})(GenderAgeParticipation);
